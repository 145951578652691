<template>
    <div>
      <b-modal
        id="modal-no-backdrop"
        content-class="create-packges"
        :class="tab"
        title="Create New"
        @hide="formSubmitted = false, onModalClose()"
        ref="modal"
      >
        <template #modal-header="{}">
          <div
            :class="
              tab === 0 ? 'buy' : tab === 1 ? 'sell' : tab === 2 ? 'hold' : 'buy'
            "
          >
            <h3>New Recommendation</h3>
          </div>
        </template>
        <p class="mb-2">
          <b-tabs
            v-model="tab"
            class="add-modal"
            :active-nav-item-class="
              tab === 0 ? 'buy' : tab === 1 ? 'sell' : tab === 2 ? 'hold' : 'buy'
              "
              @input="formSubmitted = false"
          >
            <!-- Render Tabs, supply a unique `key` to each tab -->
            <b-tab
              v-for="item in createTabs"
              :key="'dyn-tab-' + item.id"
              :title="item.title"
              @click="formData.tradeSegment = null"
            >
              <validation-observer ref="simpleRules">
                <div class="mx-2 pt-2">
                  <b-row class="m-0">
                    <b-col sm="12" md="6">
                      <SelectField
                        :id="'Recommendation Package'"
                        :placeholder="''"
                        :describedby="'recommendation-package-feedback'"
                        :label="'Recommendation Package'"
                        :name="'Recommendation Package'"
                        :valueField="'id'"
                        :textField="'text'"
                        :rules="{ required: true }"
                        :modelValue="formData.packageID"
                        @input="(val) => (formData.packageID = val)"
                        :options="recommendationPackageOptions"
                        :showValidationFeedback="formSubmitted"
                        :showValidationFeedbackmes="formSubmitted"
                      />
                    </b-col>
                    <b-col cols="12"></b-col>
                    <b-col sm="12" md="6">
                      <validation-provider
                        :rules="{ required: true }"
                        :name="'radio-validation'"
                        v-slot="validationContext"
                      >
                        <div class="d-flex mb-1">
                          <div class="">
                            <b-form-radio
                              v-model="formData.tradeSegment"
                              :name="`tradeSegment${item.id}`"
                              :class="{ 'is-invalid': formSubmitted && !validationContext.valid }"
                              value="equity"
                              >Equity</b-form-radio
                            >
                          </div>
                          <div class="ml-2">
                            <b-form-radio
                              v-model="formData.tradeSegment"
                              :name="`tradeSegment${item.id}`"
                              :class="{ 'is-invalid': formSubmitted && !validationContext.valid }"
                              value="futures"
                              >FUTURES</b-form-radio
                            >
                          </div>
                          <div class="ml-2">
                            <b-form-radio
                              v-model="formData.tradeSegment"
                              :name="`tradeSegment${item.id}`"
                              :class="{ 'is-invalid': formSubmitted && !validationContext.valid }"
                              value="options"
                              >OPTIONS</b-form-radio
                            >
                            {{ validationContext.error }}
                          </div>
                        </div>
                        <b-form-invalid-feedback :id="'radio-validation'" v-if="formSubmitted && !validationContext.valid">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </validation-provider>
                    </b-col>
                    <b-col cols="12"></b-col>
                   
                    <b-col sm="12" md="4">
                      <InputField
                        :id="'Stock name'"
                        :placeholder="''"
                        :describedby="'Stock name-feedback'"
                        :label="'Stock name'"
                        :name="'Stock name'"
                        :rules="{ required: true }"
                        :modelValue="formData.symbol"
                        @input="(val) => (formData.symbol = val)"
                        :showValidationFeedback="formSubmitted"
                        :showValidationFeedbackmes="formSubmitted"
                        />
                    </b-col>
                    <b-col sm="12" md="4">
                      <SelectField
                        :id="'Recommendation Period'"
                        :placeholder="''"
                        :describedby="'Recommendation-Period-feedback'"
                        :label="'Recommendation Period'"
                        :name="'Recommendation Period'"
                        :rules="{ required: true }"
                        :modelValue="formData.tradePeriod"
                        @input="(val) => (formData.tradePeriod = val)"
                        :options="recommendationOptions"
                        :showValidationFeedback="formSubmitted"
                        :showValidationFeedbackmes="formSubmitted"
                      />
                    </b-col>
                    <b-col sm="12" md="4"> </b-col>
                    <b-col sm="12" md="2">
                      <label for="">Entry Price</label>
                        <InputField
                          :id="'Entry Price'"
                          type="number"
                          :placeholder="''"
                          :describedby="''"
                          :label="''"
                          :name="'min price'"
                          :rules="{ required: true }"
                          :modelValue="formData.entryPrice"
                          @input="(val) => (formData.entryPrice = val)"
                          :showValidationFeedback="formSubmitted"
                          :showValidationFeedbackmes="formSubmitted"
                        />
                    </b-col>
                    <b-col sm="12" md="2">
                      <label for="">Target Price</label>
                        <InputField
                          :id="''"
                          type="number"
                          :placeholder="''"
                          :describedby="'max-price-loss-feedback'"
                          :label="''"
                          :name="'max-price'"
                          :rules="{ required: true }"
                          :modelValue="formData.targetPrice"
                          @input="(val) => (formData.targetPrice = val)"
                          :showValidationFeedback="formSubmitted"
                          :showValidationFeedbackmes="formSubmitted"
                        />
                    </b-col>
  
                    <b-col sm="12" md="2">
                      <InputField
                        :id="'Stop-loss'"
                        type="number"
                        :placeholder="''"
                        :describedby="'Stop-loss-feedback'"
                        :label="'Stop loss'"
                        :name="'Stop loss'"
                        :rules="{}"
                        :modelValue="formData.stopLoss"
                        @input="(val) => (formData.stopLoss = val)"
                        :showValidationFeedback="formSubmitted"
                        :showValidationFeedbackmes="formSubmitted"
                      />
                    </b-col>

                    <b-col sm="12" md="2" v-if="formData.tradeSegment == 'options' || formData.tradeSegment == 'futures' ">
                      <InputField
                        :id="'Lot-Size'"
                        type="number"
                        :placeholder="''"
                        :describedby="'Lot-Size-feedback'"
                        :label="'Lot Size'"
                        :name="'Lot size'"
                        :rules="{}"
                        :modelValue="formData.lotSize"
                        @input="(val) => (formData.lotSize = val)"
                        :showValidationFeedback="formSubmitted"
                        :showValidationFeedbackmes="formSubmitted"
                      />
                    </b-col>

                    <b-col sm="12" md="4">
                      <SelectField
                        :id="'Time To Target'"
                        :placeholder="''"
                        :describedby="'Target-Date-feedback'"
                        :label="'Time To Target'"
                        :name="'Time To Target'"
                        :rules="{ required: true }"
                        :modelValue="formData.targetTime"
                        @input="(val) => (formData.targetTime = val)"
                        :options="tradeDateOptions"
                        :showValidationFeedback="formSubmitted"
                        :showValidationFeedbackmes="formSubmitted"

                      />
                    </b-col>
                    <!-- <b-col cols="12"><hr /></b-col> -->
                    <!-- <b-col cols="12" md="6">
                      <p class="font-weight-bolder">Disclimer</p>
                      <b-form-radio
                        v-model="formData.isAnalystOrClientsInvested"
                        :name="`invested${item.id}`"
                        value=true
                      >I've invested</b-form-radio>
                      <b-form-radio
                        v-model="formData.isAnalystOrClientsInvested"
                        :name="`invested${item.id}`"
                        value=false
                        class="mt-1"
                      >I've not invested</b-form-radio>
                    </b-col> -->
                    <b-col sm="12" md="12">
                      <InputField
                        type="textarea"
                        :id="'Outlook'"
                        :placeholder="''"
                        :describedby="'Outlook-feedback'"
                        :label="'Outlook'"
                        :name="'Outlook'"
                        :rules="{ required: true, max: 1024 }"
                        :modelValue="formData.outlook"
                        @input="(val) => (formData.outlook = val)"
                        :showValidationFeedback="formSubmitted"
                        :showValidationFeedbackmes="formSubmitted"
                      />
                    </b-col>
                    <b-col sm="12" md="12">
                      <b-form-group
                        label="Attach Research Report (Optional)"
                        label-for="UploadOther"
                      >
                        <b-form-file
                          size="lg"
                          id="other"
                          placeholder="Choose a file"
                          accept="image/jpeg,image/png,application/pdf"
                          aria-describedby="other"
                          @change="changeResearchFile"
                        ></b-form-file>
                      </b-form-group>
                    </b-col>
                    <!-- <b-col cols="12" md="4">
                      <b-form-group
                        name="radio-validation"
                        label="Publish type"
                        class="d-flex custom-checkbox"
                      >
                        <div class="d-flex">
                          <b-form-radio value="Immediate" class="mr-2"
                            >Immediate</b-form-radio
                          >
  
                          <b-form-radio value="Schedule">Schedule</b-form-radio>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col sm="12" md="4">
                      <DateField
                        :id="'date'"
                        :placeholder="''"
                        :describedby="'date-feedback'"
                        :label="''"
                        :name="'Date'"
                        :rules="{}"
                        :modelValue="date"
                        @input="(val) => (date = val)"
                      />
                    </b-col> -->
                  </b-row>
                </div>
              </validation-observer>
            </b-tab>
          </b-tabs>
        </p>
        <template #modal-footer="{ cancel }">
          <div
            class="radio-button"
            :class="
              tab === 0 ? 'buy' : tab === 1 ? 'sell' : tab === 2 ? 'hold' : 'buy'
            "
          ></div>
          <b-button size="lg" class="cancel" @click="cancel()"> Cancel </b-button>
          <b-button size="lg" class="publish" @click="save()"> Publish </b-button>
        </template>
      </b-modal>
    </div>
</template>



<script>
import { ref, watch, onMounted, inject, reactive } from "@vue/composition-api";
import InputField from "@/views/components/form/InputField.vue";
import SelectField from "@/views/components/form/SelectField.vue";
import DateField from "@/views/components/form/DateField.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from 'vue';
import { v4 as uuidv4 } from 'uuid';

/* eslint-disable global-require */
export default {
  components: {
    SelectField,
    InputField,
    DateField,
  },
  props: {
    recommendationData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    packagesList:{
      type: Array,
      default: () => {
        return []
      }
    }
  },
  setup(props) {
    const $http = inject("$http");
    const tab = ref();
    const modal= ref(null)
    const tabs = [
      { id: 1, title: "live" },
      { id: 2, title: "draft" },
      { id: 3, title: "inactive" },
    ];
    const createTabs = [
      { id: 0, title: "buy" },
      { id: 1, title: "sell" },
      // { id: 2, title: "hold" },
    ];
    const recommendationOptions = [
      { value: null, text: "Please select an option" },
      { value: "short_term", text: "Short term" },
      { value: "medium_term", text: "Medium term" },
      { value: "long_term", text: "Long term" },
      { value: "intraday", text: "Intraday" },
      { value: "btst", text: "Btst" },
      { value: "stbt", text: "Stbt" },
    ];

    const tradeDateOptions= [
      { value: null, text: "Please select an option" },
      { value: "1 Day", text: "1 Day" },
      { value: "2 Day", text: "2 Day" },
      { value: "3 Day", text: "3 Day" },
      { value: "4 Day", text: "4 Day" },
      { value: "5 Day", text: "5 Day" },
      { value: "6 Day", text: "6 Day" },
      { value: "7 Day", text: "7 Day" },
      { value: "2 Week", text: "2 Week" },
      { value: "3 Week", text: "3 Week" },
      { value: "4 Week", text: "4 Week" },
      { value: "5 Week", text: "5 Week" },
      { value: "6 Week", text: "6 Week" },
      { value: "7 Week", text: "7 Week" },
      { value: "2 Month", text: "2 Month" },
      { value: "3 Month", text: "3 Month" },
      { value: "4 Month", text: "4 Month" },
      { value: "5 Month", text: "5 Month" },
      { value: "6 Month", text: "6 Month" },
      { value: "9 Month", text: "9 Month" },
      { value: "1 Year", text: "1 Year" },
      { value: "1 Year 3 Month", text: "1 Year 3 Month" },
      { value: "1 Year 6 Month", text: "1 Year 6 Month" },
      { value: "1 Year 9 Month", text: "1 Year 9 Month" },
      { value: "2 Year", text: "2 Year" },
      { value: "2 Year 6 Month", text: "2 Year 6 Month" },
      { value: "3 Year", text: "3 Year" },
      { value: "4 Year", text: "4 Year" },
      { value: "5 Year", text: "5 Year" },
      { value: "7 Year", text: "7 Year" },
      { value: "10 Year", text: "10 Year" },
      { value: "12 Year", text: "12 Year" },
      { value: "15 Year", text: "15 Year" },
      { value: "20 Year", text: "20 Year" },
    ]
    const recommendationPackageOptions= reactive([
      { id: null, text: "please select a package" },
    ])

    const instrumentsOptions = ref(null);

    const formData = reactive({
      msgID: uuidv4(),
      packageID: null,
      analystID: 123,
      symbol: "",
      tradeSegment: "",
      targetTime: null,
      tradePeriod: null,
      callType: tab === 0 ? 'buy' : tab === 1 ? 'sell' : tab === 2 ? 'hold' : 'buy',
      entryPrice: "",
      targetPrice: "",
      stopLoss: "",
      lotSize: "",
      outlook: "",
      isAnalystOrClientsInvested: false,
      researchID: null,
    });
    const researchFile = ref(null)
    const changeResearchFile = async (e) => {
      researchFile.value = e.target.files[0];
    }

    const fileUpload = async (docType, file, packageID) => {
      let formData = new FormData();
      formData.append("docType", docType);
      formData.append("fileName", file);
      formData.append("packageID", packageID);

      return await $http.post(`/upload/file`, formData)
        .then((response) => {
          console.log('response.data -->', response);
          if(response.data) {
            return response.data.id;
          }
        })
        .catch((error) => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: `Error`,
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: error.response,
              closeOnClick: false,
              timeout: false,
            },
          })
        });
    }


    const simpleRules = ref("");
    const formSubmitted= ref(false)
    watch(tab, (val) => {
      simpleRules.value[val]?.reset();
    });
    onMounted(async() => {
      if(props.recommendationData) {
        formData.packageID = props.recommendationData.uuid
      }
      await getPackage()
      await getInstruments()
      formSubmitted.value = false
    });
    const getPackage = async () => {
      await $http
        .get(`/advisor/packages`)
        .then((response) => {
          response.data?.packages.map(el => {
            recommendationPackageOptions.push( {id: el.uuid, text: el.title})
          })
        })
    };
    const getInstruments = async () => {
      console.log('instrumentsOptions -->', instrumentsOptions);
      await $http
        .get(`/instruments`)
        .then((response) => {
          console.log('response -->', response.data.list);
          instrumentsOptions.value = response.data.list
          
        })
    };
    const save = async () => {
      if(researchFile.value && formData.packageID) {
        formData.researchID = await fileUpload('RESEARCH', researchFile.value, formData.packageID)
      }
      
      formSubmitted.value =true
      formData.entryPrice = parseFloat(formData.entryPrice)
      formData.targetPrice = parseFloat(formData.targetPrice)
      formData.stopLoss = parseFloat(formData.stopLoss)
      formData.lotSize = parseInt(formData.lotSize)
      formData.callType = tab.value == 0 ? 'buy' : tab.value === 1 ? 'sell' : tab.value === 2 ? 'hold' : 'buy',
      formData.isAnalystOrClientsInvested = formData.isAnalystOrClientsInvested === 'true'
      simpleRules.value[tab.value].validate().then(async (success) => {
        if (success) {
          await $http
            .post(`/advisor/recommendations`,formData)
            .then((response) => {
              console.log(response, "response");
              formSubmitted.value =false
              modal.value.hide()
            })
            .catch((error) => {
              formSubmitted.value =false
              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: `Error`,
                  icon: "AlertCircleIcon",
                  variant: "danger",
                  text: error.response,
                  closeOnClick: false,
                  timeout: false,
                },
              });
            });
        }
      });
    };
    const getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    const onModalClose = () => {
      formData.msgID = uuidv4()
      formData.packageID = ""
      formData.symbol = ""
      formData.tradeSegment = ""
      formData.targetTime = null
      formData.tradePeriod = null
      formData.callType = tab === 0 ? 'buy' : tab === 1 ? 'sell' : tab === 2 ? 'hold' : 'buy'
      formData.entryPrice = ""
      formData.targetPrice = ""
      formData.stopLoss = ""
      formData.lotSize = ""
      formData.outlook = ""
    }
    return {
      tabs,
      createTabs,
      recommendationOptions,
      getValidationState,
      tab,
      formData,
      save,
      simpleRules,
      tradeDateOptions,
      recommendationPackageOptions,
      formSubmitted,
      modal,
      onModalClose,
      researchFile,
      changeResearchFile,
      instrumentsOptions
    };
  },
};
</script>

<style lang="scss">

#modal-no-backdrop .nav-item {
  text-transform: capitalize;
}

#modal-no-backdrop {
  .modal-header:has(.buy),
  .modal-footer:has(.buy) {
    background: rgba(39, 188, 148, 0.12);
  }

  .modal-header:has(.sell),
  .modal-footer:has(.sell) {
    background: rgba(235, 37, 37, 0.12);
  }

  .modal-header:has(.hold),
  .modal-footer:has(.hold) {
    background: rgba(37, 99, 235, 0.12);
  }
  .modal-body {
    padding: 0 !important;
  }

  .modal-footer:has(.buy) .publish {
    background: #27bc94 !important;
  }

  .modal-footer:has(.sell) .publish {
    background: #fe5722 !important;
  }

  .modal-footer:has(.hold) .publish {
    background: #2563eb !important;
  }
  .modal-footer .cancel {
    background: #c4c4c4 !important;
  }
  .modal-footer button {
    color: #fff !important;
    border: 0px !important;
    padding: 10px 20px;
    font-weight: 700;
  }
  .add-modal ul {
    background: rgba(248, 248, 248, 1);
    margin-left: 0px !important;
    padding-left: 16px;
    margin-bottom: 0px !important;
  }
  .nav-link {
    padding: 1.61rem 5rem !important;
    font-weight: 700 !important;
    &.active {
      &.buy {
        color: #27bc94 !important;
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 3px;
          transition: transform 0.3s;
          background: #27bc94 !important;
        }
      }

      &.sell {
        color: #fe5722 !important;
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 3px;
          transition: transform 0.3s;
          background: #fe5722 !important;
        }
      }

      &.hold {
        color: #2563eb !important;
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 3px;
          transition: transform 0.3s;
          background: #2563eb !important;
        }
      }
    }
    &:after {
      background: transparent !important;
    }
  }
  .radio-button {
    margin: 0;
    margin-right: auto;
  }
}

.form-control.is-valid {
  background-image: none;
}
.custom-select.is-valid {
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23d8d6de' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 1rem center/10px 10px no-repeat
}
</style>